import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Container, Fab, Typography } from '@mui/material';
import { EditGroupModal, NewGroupModal } from '@components/modals';
import { Add } from '@mui/icons-material';
import { useGroupAdmin } from './GroupAdmin.hook';
import { createTheme } from '@mui/material/styles';
import { DebugPanel } from '@components';

export const GroupAdmin: React.FC<React.PropsWithChildren> = () => {
  const {
    groups,
    selectedGroup,
    groupSelected,
    groupModalClose,
    handleParticipantModalClose,
    handleAddParticipant,
    handleShowAddParticipantDialog,
    onRemoveParticipant,
    showEditParticipantsModal,
    showEditGroupModal,
    showNewGroupModal,
    handleCancel,
    handleNewGroupChange,
    handleRoleChange,
    handleSaveGroup,
    handleCreateGroup,
    handleGroupChange,
    handlePrevStep,
    handleNextStep,
    activeStep,
    cols,
  } = useGroupAdmin();

  const theme = React.useMemo(() => createTheme(), []);
  const chats = groups.groups.map((x) => {
    return { id: x.id, name: x.name, chatSid: x.chatSid };
  });
  return (
    <Container component={'section'}>
      <Box padding={2}>
        <Typography variant={'h4'}>Groups</Typography>
      </Box>
      <Container>
        <DebugPanel displayItem={chats}></DebugPanel>
        <DataGrid
          autoHeight={true}
          sx={{ cursor: 'pointer', fontFamily: theme.typography.fontFamily }}
          slotProps={{
            footer: { sx: { fontFamily: theme.typography.fontFamily } },
            pagination: { sx: { fontFamily: theme.typography.fontFamily } },
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[5]}
          sortModel={[{ field: 'id', sort: 'asc' }]}
          density={'compact'}
          columns={cols}
          rows={groups.groups}
          onCellClick={groupSelected}
          disableRowSelectionOnClick
        ></DataGrid>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} padding={4}>
          <Fab variant={'extended'} onClick={handleCreateGroup}>
            Add Group
            <Add />
          </Fab>
        </Box>
      </Container>

      <EditGroupModal
        onRoleChanged={handleRoleChange}
        onParticipantClose={handleParticipantModalClose}
        onSaveGroup={handleSaveGroup}
        cancelModal={handleCancel}
        onGroupChange={handleGroupChange}
        group={selectedGroup}
        open={showEditGroupModal}
        participants={selectedGroup.participants}
        onClose={groupModalClose}
        onAddParticipant={handleAddParticipant}
        showAddParticipantDialog={showEditParticipantsModal}
        onShowAddParticipantDialog={handleShowAddParticipantDialog}
        handleRemoveParticipant={onRemoveParticipant}
      ></EditGroupModal>

      <NewGroupModal
        onRoleChanged={handleRoleChange}
        cancelModal={handleCancel}
        onGroupChange={handleNewGroupChange}
        group={selectedGroup}
        open={showNewGroupModal}
        onAddParticipant={handleAddParticipant}
        onClose={handleSaveGroup}
        onRemoveParticipant={onRemoveParticipant}
        onNextStep={handleNextStep}
        onPrevStep={handlePrevStep}
        activeStep={activeStep}
      ></NewGroupModal>
    </Container>
  );
};
