import { GoalSummaryView, Lookup } from '@views';
import { ActivityStore, ActivityTrackingStore, GoalStore } from '@stores';
import apiResponse from '../../api/ApiResponse';
import { ToDate } from '../../utils/DateUtils';
import { DateTime } from 'luxon';

export class AdminActivityViewCreator {
  activities: Lookup[] = [];

  constructor(
    private readonly activityTrackingStore: ActivityTrackingStore = new ActivityTrackingStore(),
    private readonly goalStore: GoalStore = new GoalStore(),
    private readonly activityStore: ActivityStore = new ActivityStore()
  ) {
    activityStore.readAll().then((response) => {
      if (response.status === 'SUCCESS') this.activities = response.result as Lookup[];
    });
  }

  public async createView(participantId: number) {
    const trackedActivities = await apiResponse.handleApiResponse(
      async () => await this.activityTrackingStore.readAllByCohortUser(participantId)
    );

    const participantGoals = await apiResponse.handleApiResponse(
      async () => await this.goalStore.AdminReadByCohortUserId(participantId)
    );

    return {
      trackedActivities: trackedActivities.map((activity: TrackedActivity) => {
        return {
          id: activity.id,
          activity: this.getActivity(activity.activityId)?.name || '',
          activityId: activity.activityId,
          name: activity.name,
          date: activity.dateStamp,
          duration: activity.duration,
        };
      }),
      goals: participantGoals.goals.map((goal: GoalSummaryView) => {
        return {
          ...goal,
          pickerText: `${goal.goalText} (${ToDate(goal.startDate).toFormat(
            'MMM dd yyyy'
          )} - ${ToDate(goal.endDate).toFormat('MMM dd yyyy')})`,
        };
      }),
    };
  }

  private getActivity(activityId: number) {
    return this.activities.find((x) => x.id === activityId);
  }
}
type TrackedActivity = {
  id: number;
  activityId: number;
  name: string;
  dateStamp: DateTime;
  duration: number;
};
