import { ActivityTrackingStore } from '@stores';
import { DateTime } from 'luxon';

export class AdminSaveActivityAction {
  constructor(private readonly activityTrackingStore: ActivityTrackingStore = new ActivityTrackingStore()) {}

  public async Execute(activity: TrackedActivity, participantId: number) {
    return await this.activityTrackingStore.write({
      ...activity,
      cohortUserId: participantId,
      timeframeId: 1,
    });
  }
}
type TrackedActivity = {
  id: number;
  activityId: number;
  name: string;
  dateStamp: DateTime;
  duration: number;
};
