import { useEffect, useState } from 'react';
import { SelectChangeEvent } from '@mui/material';
import { CohortSurveyView, CohortSurveyViewCreator } from '../../view-creator/CohortSurveyViewCreator';
import { GroupView } from '../../views/GroupView';
import { DateTime } from 'luxon';
import { SaveGroupSurveyAction } from '../../actions/SaveGroupSurveyAction';
import { PickerValidDate } from '@mui/x-date-pickers';
import { SurveySummaryView, SurveySummaryViewCreator } from '../../view-creator/SurveySummaryViewCreator';

export const useGroupSurveyConfigurationModal = () => {
  const [surveys, setSurveys] = useState<SurveySummaryView | null>(null);
  const [selectedCohortSurvey, setSelectedCohortSurvey] = useState<CohortSurveyView | null>(null);
  const [selectedSurveyId, setSelectedSurveyId] = useState<'' | number>('');

  useEffect(() => {
    new SurveySummaryViewCreator().createView().then(setSurveys);
  }, []);
  const showSections = async (e: SelectChangeEvent<number>, groupId: number) => {
    setSelectedSurveyId(Number(e.target.value));
    const cohortSurvey = await new CohortSurveyViewCreator().CreateView(Number(e.target.value), groupId);

    setSelectedCohortSurvey(cohortSurvey);
  };

  const handleSaveSurveyConfiguration = async (group: GroupView) => {
    // TODO: Handle update of survey configuration
    if (!selectedCohortSurvey || !selectedCohortSurvey.surveyId) {
      return;
    }

    const body = selectedCohortSurvey.sections.map((section) => {
      return {
        cohortSurveyId: section.cohortSurveyId,
        surveyId: selectedCohortSurvey.surveyId,
        sectionId: section.sectionId,
        cohortId: group.id,
        availableDate: section.availableDate,
      };
    });

    await new SaveGroupSurveyAction().Execute(body);
  };

  const onDateSelected = (
    selectedDate: PickerValidDate | null,
    item: {
      sectionId: number;
      title: string;
      availableDate: DateTime;
    }
  ) => {
    if (DateTime.isDateTime(selectedDate)) item.availableDate = selectedDate;
  };

  return {
    surveys,
    setSurveys,
    selectedSurvey: selectedCohortSurvey,
    setSelectedSurvey: setSelectedCohortSurvey,
    selectedSurveyId,
    setSelectedSurveyId,
    showSections,
    handleSaveSurveyConfiguration,
    onDateSelected,
  };
};
