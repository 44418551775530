import { FC, useEffect, useState } from 'react';
import {
  Alert,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { ParticipantView } from '../../views/ParticipantView';
import { GoalSummaryView } from '@views';
import { DateTime } from 'luxon';
import { DataGrid } from '@mui/x-data-grid';
import { DebugPanel } from '@components';
import { GroupedBox } from '../GroupedBox';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ToDate } from '../../utils/DateUtils';
import { AdminActivityViewCreator } from '../../view-creator/Admin/AdminActivityViewCreator';
import { AdminSaveActivityAction } from '../../actions/Admin/AdminSaveActivityAction';

export interface AddActivityDialogProps {
  open: boolean;
  onClose: () => void;
  participant: ParticipantView;
}

export const AddActivityDialog: FC<AddActivityDialogProps> = ({ open, onClose, participant }) => {
  const [view, setView] = useState<{
    trackedActivities: TrackedActivityView[];
    goals: any[];
  }>({ trackedActivities: [], goals: [] });
  const [reload, setReload] = useState<boolean>(true);
  useEffect(() => {
    if (reload) {
      new AdminActivityViewCreator().createView(participant!.id).then(setView);
      setReload(false);
    }
  }, [reload]);

  function handleAddActivity() {}

  const defaultActivity = {
    id: 0,
    name: '',
    cohortUserId: participant?.id,
    activityId: 8,
    dateStamp: DateTime.now(),
    duration: 0,
    timeframeId: 0,
  };
  const [newActivity, setNewActivity] = useState(defaultActivity);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  return (
    <Dialog open={open} fullWidth={true}>
      <DebugPanel displayItem={newActivity}></DebugPanel>
      <Collapse in={showAlert}>
        <Alert severity={'success'} variant={'filled'} sx={{ fontFamily: 'roboto' }}>
          Activity Saved Successful
        </Alert>
      </Collapse>
      <DialogTitle>Add Activity</DialogTitle>
      <DialogContent>
        <DataGrid
          slotProps={{
            columnHeaders: { style: { fontFamily: 'roboto' } },
            row: { style: { fontFamily: 'roboto' } },
            noResultsOverlay: { style: { fontFamily: 'roboto' } },
            noRowsOverlay: { style: { fontFamily: 'roboto' } },
          }}
          hideFooter={true}
          hideFooterPagination={true}
          rows={view.trackedActivities}
          columns={[
            {
              field: 'id',
              headerName: 'ID',
            },
            {
              field: 'activity',
              headerName: 'Activity',
              flex: 1,
            },
            {
              field: 'duration',
              headerName: 'Duration',
              valueFormatter: (value, row) => {
                return Number(row.duration) === 1 ? `${row.duration} Minute` : `${row.duration} Minutes`;
              },
            },
            {
              field: 'date',
              headerName: 'Date',
              valueFormatter: (value, row) => {
                return ToDate(row.date).toFormat('MMM dd yyyy');
              },
            },
          ]}
        ></DataGrid>
        <br />
        <GroupedBox title={'New Activity'}>
          <Grid2 container gap={2} flexDirection={'column'}>
            <InputLabel id={'label-participant-goals'} size={'small'}>
              Participant Goals
            </InputLabel>
            <FormControl fullWidth>
              <Select
                variant={'outlined'}
                labelId={'label-participant-goals'}
                onChange={(e) => {
                  setNewActivity((old) => {
                    const selectedGoal = e.target.value as GoalSummaryView;
                    return {
                      ...old,
                      activityId: selectedGoal.activity,
                      duration: selectedGoal.duration,
                      cohortUserId: participant?.id,
                      name: selectedGoal.goalText,
                      timeframeId: selectedGoal.timeframe,
                    };
                  });
                }}
              >
                {view.goals.map((g) => {
                  return (
                    <MenuItem key={g.id} value={g}>
                      <Typography variant={'caption'}>{g.pickerText}</Typography>
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                value={newActivity.dateStamp}
                label={'Activity Date'}
                onChange={(e) => {
                  setNewActivity((old) => {
                    return {
                      ...old,
                      dateStamp: e as DateTime<true>,
                    };
                  });
                }}
              ></DatePicker>
            </FormControl>
          </Grid2>
          <Button
            onClick={async () => {
              const result = await new AdminSaveActivityAction().Execute(newActivity, participant.id);
              if (result.status === 'SUCCESS') {
                setShowAlert(true);
                setTimeout(() => {
                  setReload(true);
                  setShowAlert(false);
                }, 3000);
              }
            }}
          >
            Save Activity for Goal
          </Button>
        </GroupedBox>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

type TrackedActivityView = {
  id: number;
  activity: string;
  activityId: number;
  name: string;
  date: DateTime;
  duration: number;
};
